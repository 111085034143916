.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
@media screen and (max-width: 320px) {
  .form {
    width: calc(100vw - 20px);
    padding: 10px;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .form {
    width: calc(100vw - 40px);
    padding: 20px;
  }
}
@media screen and (min-width: 376px) {
  .form {
    width: 355px;
    padding: 20px;
  }
}
.container {
  width: 100vw;
  height: 100vh;
  background: #fff url("../../images/login-bg.jpg") 50% / cover no-repeat;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 50px);
  padding: 20px;
}
.form {
  background: #ffffff;
  border-radius: 6px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.header > img {
  height: 60px;
}
.header > h1 {
  margin: 0;
  padding: 0;
  color: #595959;
  font-size: 22px;
  margin-left: 16px;
}
.form-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}
