body {
  -webkit-font-smoothing: antialiased;
}
body .ant-btn {
  text-shadow: none;
  box-shadow: none;
}
body .ant-modal-confirm-body .anticon.anticon-exclamation-circle {
  color: #ff2115;
}
textarea {
  resize: none;
}
.add-line {
  height: 36px;
  border-radius: 5px;
  border: 1px dashed #ebebeb;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  margin-top: 10px;
}
.add-line:hover {
  border-color: #22b8ff;
  color: #22b8ff;
}
.opt,
.opt-10,
.opt-5 {
  color: #22b8ff;
  cursor: pointer;
}
.opt + .opt {
  margin-left: 10px;
}
.opt-10 + .opt-10 {
  margin-left: 10px;
}
.opt-5 + .opt-5 {
  margin-left: 5px;
}
.drop-btn {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: transparent 50% / cover no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAANlBMVEUAAADLy8vNzc3Pz8/MzMzLy8vMzMzPz8/MzMzMzMzMzMzLy8vNzc3Ly8vNzc3Ly8vOzs7MzMwjzu6qAAAAEXRSTlMAwGAQv8/QIPDvUEBwgH9PPx1pDooAAAEVSURBVHhevdZRb4MwDARggxucBLrN///PjuAqknWlnl92Up+4T6I6FKD36a11SmTVM2uqDyLogwj6III+iLC/bfabIurzorrwX4RYnwYgE/IRPK1vwMTxEUi9+gYuUSW4p840wSl2pjATBMkC+RIA7iLu1fgOSBt74F77HdhtQdiX7gBsbn0GAAL6AEBAHwAI10eAoutIefg01fbwKTrSqWkqjWoO1NctbYtPVa2Lz3aB/hqhxH+6zCmcAAB9EACgPwUA7HsBAPrpx9uLyneAK/SJ5BACABf/4dSAyLc/yH446LexxwRctMWH8RAGuMSHsW1ugEt03M/NeQC3byTKqaCffynGYs2/2FNiTX+cJNJr7e8LvyeUIX2x+FHsAAAAAElFTkSuQmCC");
  cursor: move;
}
.full-main {
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.his-btn {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: none no-repeat center / contain;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000' fill='%23999'%3E%3Cpath d='M425 825H200c-30 0-50-20-50-50V225c0-30 20-50 50-50h450c30 0 50 20 50 50v125c0 15 10 25 25 25s25-10 25-25V225c0-55-45-100-100-100H200c-55 0-100 45-100 100v550c0 55 45 100 100 100h225c15 0 25-10 25-25s-10-25-25-25z'/%3E%3Cpath d='M275 475c-15 0-25 10-25 25s10 25 25 25h150c15 0 25-10 25-25s-10-25-25-25H275zm325-125c0-15-10-25-25-25H275c-15 0-25 10-25 25s10 25 25 25h300c15 0 25-10 25-25zM675 825c-95 0-175-80-175-175s80-175 175-175 175 80 175 175-80 175-175 175zm0-400c-125 0-225 100-225 225s100 225 225 225 225-100 225-225-100-225-225-225z'/%3E%3Cpath d='M700 640.1c1.1.8 2.1 1.7 3 2.6l35.4 35.3c9.5 9.8 9.3 25.4-.3 35-9.6 9.6-25.2 9.8-35 .3L667.7 678c-1.8-1.8-3.3-3.8-4.5-6-8.1-4.3-13.2-12.8-13.2-22V550c0-13.8 11.2-25 25-25s25 11.2 25 25v90.1z'/%3E%3C/svg%3E");
}
.his-btn:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000' fill='%2322b8ff'%3E%3Cpath d='M425 825H200c-30 0-50-20-50-50V225c0-30 20-50 50-50h450c30 0 50 20 50 50v125c0 15 10 25 25 25s25-10 25-25V225c0-55-45-100-100-100H200c-55 0-100 45-100 100v550c0 55 45 100 100 100h225c15 0 25-10 25-25s-10-25-25-25z'/%3E%3Cpath d='M275 475c-15 0-25 10-25 25s10 25 25 25h150c15 0 25-10 25-25s-10-25-25-25H275zm325-125c0-15-10-25-25-25H275c-15 0-25 10-25 25s10 25 25 25h300c15 0 25-10 25-25zM675 825c-95 0-175-80-175-175s80-175 175-175 175 80 175 175-80 175-175 175zm0-400c-125 0-225 100-225 225s100 225 225 225 225-100 225-225-100-225-225-225z'/%3E%3Cpath d='M700 640.1c1.1.8 2.1 1.7 3 2.6l35.4 35.3c9.5 9.8 9.3 25.4-.3 35-9.6 9.6-25.2 9.8-35 .3L667.7 678c-1.8-1.8-3.3-3.8-4.5-6-8.1-4.3-13.2-12.8-13.2-22V550c0-13.8 11.2-25 25-25s25 11.2 25 25v90.1z'/%3E%3C/svg%3E");
}
.opt-new {
  padding: 0 10px;
  color: #22b8ff;
  cursor: pointer;
  white-space: nowrap;
}
.opt-new:first-child {
  padding-left: 0;
}
.opt-new:last-child {
  padding-right: 0;
}
.opt-new + .opt-new {
  position: relative;
}
.opt-new + .opt-new::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  content: "";
  width: 1px;
  height: 14px;
  background-color: #ebebeb;
}
.opt-new.danger {
  color: #ff2115;
}
.ant-checkbox-wrapper {
  margin-left: 0 !important;
  margin-right: 8px !important;
}
.ant-checkbox-wrapper:last-child {
  margin-right: 0 !important;
}
.ant-form-large .ant-input {
  padding: 6.5px 11px;
  font-size: 16px;
}
