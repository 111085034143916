@import "../../styles/_variable.less";

@media screen and (max-width: 320px) {
  .form {
    width: calc(100vw - 20px);
    padding: 10px;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .form {
    width: calc(100vw - 40px);
    padding: 20px;
  }
}

@media screen and (min-width: 376px) {
  .form {
    width: 355px;
    padding: 20px;
  }
}

.container {
  width: 100vw;
  height: 100vh;
  background: #fff url("../../images/login-bg.jpg") 50% / cover no-repeat;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 50px);
  padding: 20px;
}

.form {
  background: rgb(255, 255, 255);
  border-radius: 6px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & > img {
    height: 60px;
  }

  & > h1 {
    margin: 0;
    padding: 0;
    color: @text;
    font-size: 22px;
    margin-left: 16px;
  }
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;@text: #595959;@text-strong: #262626;@text-second: #8c8c8c;@text-disabled: #bfbfbf;@placeholder-color: #999;@bg-light: #f8f8f8;@border: #ebebeb;@primary: #22b8ff;@strong: #46cadb;@danger: #ff2115;@warn: #ffa431;@green: #9cd015;@fc-height: 32px;@header-height: 70px;